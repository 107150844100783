import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { finalize, map } from 'rxjs';

import { GroupNew } from '../policy-details/policy-details.types';
import { UserService } from '../user/user.service';
import { GeneralPolicy, PoliciesByCustomer } from './policies.types';

@Injectable({
	providedIn: 'root',
})
export class PoliciesService {
	apiService = inject(ApiService);
	userService = inject(UserService);

	isLoading = signal<boolean>(false);

	addPolicy(data: GroupNew) {
		const access = this.userService.user().selectedGroup;

		return this.apiService.post<string>('/android/groups', data, { params: { access } });
	}

	deletePolicy(id: string) {
		return this.apiService.delete(`/android/groups/${id}`);
	}

	getByCustomer(customerCode: string, customerId?: string) {
		this.isLoading.set(true);

		return this.apiService
			.get<GeneralPolicy[]>(`/android/groups/getbycustomer/${customerCode}`)
			.pipe(
				finalize(() => {
					this.isLoading.set(false);
				}),
				map((response): PoliciesByCustomer => {
					const ownerPolicies = response.filter((policy) => policy.ownerId === customerId);
					const allPolicies = response.filter((policy) => policy.ownerId !== customerId);

					return { ownerPolicies, allPolicies };
				}),
			);
	}

	getPublicPolicies() {
		this.isLoading.set(true);

		return this.apiService.get<GeneralPolicy[]>('/android/groups/getpublic').pipe(
			finalize(() => {
				this.isLoading.set(false);
			}),
			map((response): PoliciesByCustomer => {
				return { ownerPolicies: [], allPolicies: response };
			}),
		);
	}
}
